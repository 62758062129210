import { lazy as loadable } from '@loadable/component'

import { createLocalizedComponent } from './create-localized-component'

const LOCALIZED_COMPONENTS = {
  __default__: loadable(() =>
    import('./registration-drawer-casinosky-phone-validation')
  ),
  NL: loadable(() =>
    import('./registration-drawer-casinosky-phone-validation')
  ),
  FR: loadable(() =>
    import('./registration-drawer-casinosky-phone-validation')
  ),
  DE: loadable(() =>
    import('./registration-drawer-casinosky-phone-validation')
  ),
}

export const RegistrationDrawer = createLocalizedComponent(
  'RegistrationDrawer',
  LOCALIZED_COMPONENTS
)

export default RegistrationDrawer
